var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "editor-version-desc-info-pages" },
    [
      _c(
        "a-popover",
        { attrs: { placement: "topLeft" } },
        [
          _c("template", { slot: "content" }, [
            _c("div", { staticClass: "shiwu-tapian-img-list-sp" }, [
              _vm.info.shiwuImgFront
                ? _c("img", {
                    attrs: { src: _vm.info.shiwuImgFront },
                    on: {
                      click: function($event) {
                        return _vm.handleShowBigImg(
                          [_vm.info.shiwuImgFront, _vm.info.shiwuImgBack],
                          0
                        )
                      }
                    }
                  })
                : _vm._e(),
              _vm.info.shiwuImgBack
                ? _c("img", {
                    attrs: { src: _vm.info.shiwuImgBack },
                    on: {
                      click: function($event) {
                        return _vm.handleShowBigImg(
                          [_vm.info.shiwuImgFront, _vm.info.shiwuImgBack],
                          1
                        )
                      }
                    }
                  })
                : _vm._e()
            ]),
            _c("div", { staticClass: "shiwu-tapian-img-list-sp" }, [
              _vm.info.tapianImgFront
                ? _c("img", {
                    attrs: { src: _vm.info.tapianImgFront },
                    on: {
                      click: function($event) {
                        return _vm.handleShowBigImg(
                          [_vm.info.tapianImgFront, _vm.info.tapianImgBack],
                          0
                        )
                      }
                    }
                  })
                : _vm._e(),
              _vm.info.tapianImgBack
                ? _c("img", {
                    attrs: { src: _vm.info.tapianImgBack },
                    on: {
                      click: function($event) {
                        return _vm.handleShowBigImg(
                          [_vm.info.tapianImgFront, _vm.info.tapianImgBack],
                          1
                        )
                      }
                    }
                  })
                : _vm._e()
            ])
          ]),
          _c("div", { staticClass: "shiwu-tapian-img-list" }, [
            _vm.info.shiwuImgFront
              ? _c("img", { attrs: { src: _vm.info.shiwuImgFront } })
              : _vm._e(),
            _vm.info.shiwuImgBack
              ? _c("img", { attrs: { src: _vm.info.shiwuImgBack } })
              : _vm._e()
          ]),
          _c("div", { staticClass: "shiwu-tapian-img-list" }, [
            _vm.info.tapianImgFront
              ? _c("img", { attrs: { src: _vm.info.tapianImgFront } })
              : _vm._e(),
            _vm.info.tapianImgBack
              ? _c("img", { attrs: { src: _vm.info.tapianImgBack } })
              : _vm._e()
          ])
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }