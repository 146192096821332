import {objArrMapObj} from "@/untils/otherEvent"
import {getBookImgType} from "@/axios/apis"

/** 性质列表
 * tapian(6,"标准拓片"),
 * shiwu(8,"标准实物"),
 * muqian(10,"母钱"),
 * bianhua(12,"变化版"),
 * dayang(14,"大样"),
 * xiaoyang(16,"小样"),
 * zhongyang(18,"中样");
 * 22 雕母  24 部颁样钱 26 样钱 28 试铸样钱 30 母坯 32异书
 */
export const NATURE_TYPE_LIST = [
    {name: '标准拓片', value: 6},
    {name: '标准实物', value: 8},
    {name: '母钱', value: 10},
    {name: '变化版', value: 12},
    {name: '大样', value: 14},
    {name: '小样', value: 16},
    {name: '中样', value: 18},
    {name: '雕母', value: 22},
    {name: '部颁样钱', value: 24},
    {name: '样钱', value: 26},
    {name: '试铸样钱', value: 28},
    {name: '母坯', value: 30},
    {name: '异书', value: 32},
]

/** 性质对应的类名 */
export const NATURE_TYPE_CLASS_TYPE = {
    6: 'color-blue',
    8: 'color-green',
    10: 'color-hot-pink',
    12: 'color-orange-red',
    14: 'color-purple',
    16: 'color-red-zu-4',
    18: 'color-red-zu-5',
    20: 'color-red-zu-6',
}

/** 交易平台 1 华夏  2 钱币天堂  3 微拍堂  4 闲斋    5 古泉园地   10 SBP */
export const TRADING_PLATFORM_TYPE_LIST = [
    {name: '斗泉', value: 100},
    {name: 'SBP', value: 10},
    {name: '古泉园地', value: 5},
    {name: '闲斋', value: 4},
    {name: '微拍堂', value: 3},
    {name: '钱币天堂', value: 2},
    {name: '华夏', value: 1},
]
/** 交易平台对应类 */
export const TRADING_PLATFORM_CLASS_TYPE = {
    100: 'color-blue',
    1: 'color-red',
    2: 'color-cadetblue',
    3: 'color-red-zu-1',
    4: 'color-red-zu-2',
    5: 'color-orange',
    10: 'color-red-zu-3'
}

/** 评级公司 0裸币   1 PCGS   2公博   10 保粹  11华夏  14 轩德  16 闻德  18 园地评级*/
export const RATING_COMPANY_TYPE_LIST = [
    {name: '裸币', value: 0},
    {name: '华夏评级', value: 11},
    {name: '轩德泉评', value: 14},
    {name: '保粹/致臻', value: 10},
    {name: '公博评级', value: 2},
    {name: '园地评级', value: 18},
    {name: 'PCGS', value: 1},
    {name: '闻德', value: 16},
    {name: '众诚详评', value: 12},
]

/** 评级公司对应的类 */
export const RATING_COMPANY_CLASS_TYPE = {
    0: 'color-black',
    11: 'color-red',
    14: 'color-blue',
    10: 'color-orange',
    2: 'color-tomato',
    1: 'color-purple',
    18: 'color-lime-green',
    16: 'color-orange-red',
    12: 'color-hot-pink'
}

export const ratingCompanyTypeListMapText = (value) => {
    return objArrMapObj('value', 'name', RATING_COMPANY_TYPE_LIST)[value]
}
export const tradingPlatformTypeMapText = (value) => {
    return objArrMapObj('value', 'name', TRADING_PLATFORM_TYPE_LIST)[value]
}