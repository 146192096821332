<template>
  <div class="editor-version-desc-info-pages">
    <a-popover placement="topLeft">
      <template slot="content">
        <div class="shiwu-tapian-img-list-sp">
          <img v-if="info.shiwuImgFront" :src="info.shiwuImgFront" @click="handleShowBigImg([info.shiwuImgFront, info.shiwuImgBack], 0)"/>
          <img v-if="info.shiwuImgBack" :src="info.shiwuImgBack" @click="handleShowBigImg([info.shiwuImgFront, info.shiwuImgBack], 1)"/>
        </div>
        <div class="shiwu-tapian-img-list-sp">
          <img v-if="info.tapianImgFront" :src="info.tapianImgFront" @click="handleShowBigImg([info.tapianImgFront, info.tapianImgBack], 0)"/>
          <img v-if="info.tapianImgBack" :src="info.tapianImgBack" @click="handleShowBigImg([info.tapianImgFront, info.tapianImgBack], 1)"/>
        </div>
      </template>
      <div class="shiwu-tapian-img-list">
        <img v-if="info.shiwuImgFront" :src="info.shiwuImgFront"/>
        <img v-if="info.shiwuImgBack" :src="info.shiwuImgBack"/>
      </div>
      <div class="shiwu-tapian-img-list">
        <img v-if="info.tapianImgFront" :src="info.tapianImgFront"/>
        <img v-if="info.tapianImgBack" :src="info.tapianImgBack"/>
      </div>
    </a-popover>
  </div>
</template>
<script>
export default {
  props: ['info'],
  data() {
    return {}
  },
  methods: {
    /** 查看大图 */
    handleShowBigImg(list, ind) {
      const imgArray = list.map(el => {
        return { img_url: el }
      })
      this.$previewImg({
        list: imgArray,
        baseImgField: "img_url",
        showMute: false,
        current: ind
      });
    },
  }
}
</script>
<style scoped lang="scss">
.editor-version-desc-info-pages {
  position: absolute;
  right: 30px;
  top: 50px;
  width: 50px;
  z-index: 100;
  background-color: #f4f4f6;
}
.shiwu-tapian-img-list-sp {
  width: 400px;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: 200px;
    height: 200px;
  }
}
.shiwu-tapian-img-list {
  width: 50px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: 25px;
    height: 25px;
  }
}
</style>