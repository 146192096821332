var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    {
      attrs: {
        title: "设为封面预览",
        "ok-text": "确认设为封面",
        "cancel-text": "取消",
        maskClosable: false
      },
      on: { cancel: _vm.handleCancel, ok: _vm.handleConfirm },
      model: {
        value: _vm.showModal,
        callback: function($$v) {
          _vm.showModal = $$v
        },
        expression: "showModal"
      }
    },
    [
      _c(
        "div",
        { staticClass: "content-modal" },
        [
          _vm.modalData
            ? _c("div", { staticClass: "top-title" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.modalData.coinKindName +
                        "·" +
                        _vm.modalData.coinKindItemName +
                        "·" +
                        _vm.modalData.coinKindItemVersionName
                    ) +
                    " "
                )
              ])
            : _vm._e(),
          _c("a-divider", [_vm._v("原图")]),
          _c(
            "div",
            { staticClass: "mt-20 width-100" },
            [
              _c(
                "a-spin",
                {
                  attrs: {
                    spinning: _vm.yuanImgSpinningStatus,
                    tip: "Loading..."
                  }
                },
                [
                  _c("div", { staticClass: "img-list" }, [
                    _c("div", { staticClass: "img-box" }, [
                      _vm.frontImgUrlByYuan
                        ? _c("img", {
                            attrs: { src: _vm.frontImgUrlByYuan, alt: "" },
                            on: {
                              click: function($event) {
                                return _vm.handleShowBigImg([
                                  _vm.frontImgUrlByYuan,
                                  _vm.backImgUrlByYuan
                                ])
                              }
                            }
                          })
                        : _vm._e()
                    ]),
                    _c("div", { staticClass: "img-box" }, [
                      _vm.backImgUrlByYuan
                        ? _c("img", {
                            attrs: { src: _vm.backImgUrlByYuan, alt: "" },
                            on: {
                              click: function($event) {
                                return _vm.handleShowBigImg([
                                  _vm.backImgUrlByYuan,
                                  _vm.frontImgUrlByYuan
                                ])
                              }
                            }
                          })
                        : _vm._e()
                    ])
                  ])
                ]
              )
            ],
            1
          ),
          _c("a-divider", [_vm._v("抠图之后的图片")]),
          _c(
            "div",
            { staticClass: "mt-20 width-100" },
            [
              _c(
                "a-spin",
                {
                  attrs: {
                    spinning: _vm.cutImgSpinningStatus,
                    tip: "Loading..."
                  }
                },
                [
                  _c("div", { staticClass: "img-list" }, [
                    _c("div", { staticClass: "img-box" }, [
                      _vm.frontImgUrlByCut
                        ? _c("img", {
                            attrs: { src: _vm.frontImgUrlByCut, alt: "" },
                            on: {
                              click: function($event) {
                                return _vm.handleShowBigImg([
                                  _vm.frontImgUrlByCut,
                                  _vm.backImgUrlByCut
                                ])
                              }
                            }
                          })
                        : _vm._e()
                    ]),
                    _c("div", { staticClass: "img-box" }, [
                      _vm.backImgUrlByCut
                        ? _c("img", {
                            attrs: { src: _vm.backImgUrlByCut, alt: "" },
                            on: {
                              click: function($event) {
                                return _vm.handleShowBigImg([
                                  _vm.backImgUrlByCut,
                                  _vm.frontImgUrlByCut
                                ])
                              }
                            }
                          })
                        : _vm._e()
                    ])
                  ])
                ]
              )
            ],
            1
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }