var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "list" },
        _vm._l(_vm.list, function(item, index) {
          return _c("div", { key: index, staticClass: "item" }, [
            _c("div", { staticClass: "content" }, [
              _c(
                "div",
                { staticClass: "image-box", attrs: { id: "img-back" + index } },
                [
                  _c("div", { ref: "imgBoxEl", refInFor: true }, [
                    _c("img", {
                      staticClass: "image",
                      attrs: {
                        id: "img-box-el",
                        mode: "aspectFill",
                        src: _vm.showImage(item)
                      },
                      on: {
                        click: function($event) {
                          return _vm.previewImg(index)
                        }
                      }
                    })
                  ]),
                  _c(
                    "div",
                    {
                      staticClass: "tip",
                      style: { color: item.isDiku ? "" : "red" }
                    },
                    [_vm._v(_vm._s(item.id))]
                  )
                ]
              ),
              _c("div", { staticClass: "name" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      item.coinKindName +
                        "-" +
                        item.coinKindItemName +
                        "-" +
                        item.coinKindItemVersionName
                    ) +
                    " "
                ),
                item.isTest
                  ? _c("span", { staticClass: "color-green" }, [
                      _vm._v("（" + _vm._s(item.isTest ? "测试集" : "") + "）")
                    ])
                  : _vm._e()
              ])
            ]),
            _c(
              "div",
              { staticClass: "footer" },
              [
                _c(
                  "a-button",
                  {
                    staticClass: "move-btn",
                    staticStyle: { "margin-right": "5px" },
                    attrs: { size: "small", type: "link" },
                    on: {
                      click: function($event) {
                        return _vm.showYuanImg(item)
                      }
                    }
                  },
                  [_vm._v("原图")]
                ),
                _c(
                  "a-button",
                  {
                    staticStyle: { "margin-right": "5px", color: "orangered" },
                    attrs: { size: "small", type: "link" },
                    on: {
                      click: function($event) {
                        return _vm.moveClick(item)
                      }
                    }
                  },
                  [_vm._v("挪动")]
                ),
                _c(
                  "a-button",
                  {
                    staticClass: "move-btn",
                    staticStyle: { "margin-right": "5px" },
                    attrs: { size: "small", type: "link" },
                    on: {
                      click: function($event) {
                        return _vm.handleSameVersion(item)
                      }
                    }
                  },
                  [_vm._v("同版")]
                ),
                _c("EditorVersionDescInfo", {
                  attrs: { info: item },
                  on: {
                    changeVersionDesc: function($event) {
                      return _vm.handleChangeVersionDesc($event)
                    }
                  }
                }),
                _c(
                  "a-dropdown",
                  {
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "overlay",
                          fn: function() {
                            return [
                              _c(
                                "a-menu",
                                [
                                  _c(
                                    "a-menu-item",
                                    [
                                      _c(
                                        "a-button",
                                        {
                                          attrs: {
                                            size: "small",
                                            type: "primary"
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.logClick(item)
                                            }
                                          }
                                        },
                                        [_vm._v("日志")]
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "a-menu-item",
                                    [
                                      _c(
                                        "a-button",
                                        {
                                          staticStyle: {
                                            "margin-right": "5px"
                                          },
                                          attrs: {
                                            size: "small",
                                            type: "primary"
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.handleVersion(item)
                                            }
                                          }
                                        },
                                        [_vm._v("对版")]
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "a-menu-item",
                                    [
                                      _c(
                                        "a-popconfirm",
                                        {
                                          attrs: {
                                            title: "确定要质检此项么？",
                                            "ok-text": "确认",
                                            "cancel-text": "取消"
                                          },
                                          on: {
                                            confirm: function($event) {
                                              return _vm.handleCheck(item.id)
                                            }
                                          }
                                        },
                                        [
                                          _c(
                                            "a-button",
                                            {
                                              attrs: {
                                                size: "small",
                                                type: "primary"
                                              }
                                            },
                                            [_vm._v("质检")]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "a-menu-item",
                                    [
                                      _c(
                                        "a-button",
                                        {
                                          staticClass: "setup-btn",
                                          attrs: {
                                            size: "small",
                                            type: "primary"
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.handleCutPic(item)
                                            }
                                          }
                                        },
                                        [_vm._v("机器裁剪")]
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "a-menu-item",
                                    [
                                      _c(
                                        "a-button",
                                        {
                                          staticClass: "move-btn",
                                          staticStyle: {
                                            "margin-right": "5px"
                                          },
                                          attrs: {
                                            size: "small",
                                            type: "primary"
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.handleCutPicByHand(
                                                item
                                              )
                                            }
                                          }
                                        },
                                        [_vm._v("手动裁剪")]
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "a-menu-item",
                                    [
                                      _c(
                                        "a-button",
                                        {
                                          staticStyle: {
                                            "margin-right": "5px"
                                          },
                                          attrs: {
                                            size: "small",
                                            type: "primary"
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.handlePushCoinBookData(
                                                item
                                              )
                                            }
                                          }
                                        },
                                        [_vm._v("推送到活谱数据管理")]
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "a-menu-item",
                                    [
                                      _c(
                                        "a-button",
                                        {
                                          staticStyle: {
                                            "margin-right": "5px"
                                          },
                                          attrs: {
                                            size: "small",
                                            type: "primary"
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.handleSetCoverPic(item)
                                            }
                                          }
                                        },
                                        [_vm._v("设为封面")]
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "a-menu-item",
                                    [
                                      _c(
                                        "a-popconfirm",
                                        {
                                          attrs: {
                                            title: "确定要删除此项么？",
                                            "ok-text": "确认",
                                            "cancel-text": "取消"
                                          },
                                          on: {
                                            confirm: function($event) {
                                              return _vm.handleDeleteItem(
                                                item.id
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _c(
                                            "a-button",
                                            {
                                              attrs: {
                                                size: "small",
                                                type: "danger"
                                              }
                                            },
                                            [_vm._v("删除")]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ]
                          },
                          proxy: true
                        }
                      ],
                      null,
                      true
                    )
                  },
                  [
                    _c(
                      "a-button",
                      {
                        staticStyle: { color: "blue" },
                        attrs: { size: "small", type: "link" }
                      },
                      [_vm._v("更多")]
                    )
                  ],
                  1
                )
              ],
              1
            )
          ])
        }),
        0
      ),
      _c("ConfirmLog", { ref: "log" }),
      _c("ChangeData", { ref: "change", on: { success: _vm.editSuccess } }),
      _c("ShowImg", { ref: "showImgEl" }),
      _c("VersionPic", { ref: "showVersionPicEl" }),
      _c("VersionPicV2", { ref: "showVersionPicV2El" }),
      _c("PushCoinBookNaturePopup", { ref: "pushCoinBookNaturePopupEl" }),
      _c("SetCoverPicPopup", { ref: "setCoverPicPopupEl" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }