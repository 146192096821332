var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "editor-version-desc-info-pages" }, [
    _c(
      "div",
      { staticClass: "item-info" },
      [
        _c(
          "a-popover",
          { attrs: { title: "版别描述", trigger: "hover" } },
          [
            _c(
              "template",
              { slot: "content" },
              [
                _c("a-input", {
                  staticClass: "w-300",
                  attrs: {
                    size: "small",
                    type: "textarea",
                    placeholder: _vm.versionDescType
                      ? "内部版别描述"
                      : "外部版别描述",
                    rows: 5,
                    value: _vm.versionDescType
                      ? _vm.info.internalMark
                      : _vm.info.versionDesc
                  },
                  on: { change: _vm.handleInputVersionDesc }
                }),
                _c(
                  "div",
                  { staticClass: "flex-sp-bt-center mt-10" },
                  [
                    _c(
                      "a-radio-group",
                      {
                        attrs: {
                          "default-value": 1,
                          "button-style": "solid",
                          size: "small"
                        },
                        model: {
                          value: _vm.versionDescType,
                          callback: function($$v) {
                            _vm.versionDescType = $$v
                          },
                          expression: "versionDescType"
                        }
                      },
                      [
                        _c("a-radio-button", { attrs: { value: 1 } }, [
                          _vm._v("内部标记")
                        ]),
                        _c("a-radio-button", { attrs: { value: 0 } }, [
                          _vm._v("外部标记")
                        ])
                      ],
                      1
                    ),
                    _c(
                      "a-button",
                      {
                        staticClass: "ml-10",
                        attrs: { size: "small", type: "primary" },
                        on: { click: _vm.handleEditVersionDesc }
                      },
                      [
                        _vm._v(
                          _vm._s(_vm.versionDescType ? "修改内部" : "修改外部")
                        )
                      ]
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _c("div", { staticClass: "cur-pot color-blue" }, [
              _vm._v("版别介绍编辑")
            ])
          ],
          2
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }