import instance from "@/axios"

/** 获取原图列表，用于封面 */
export const getYuanImgByCoverPic = async (params) => {
    return instance({
        url: '/dq_admin/duibanSampleData/getVersionRealImage',
        method: 'GET',
        params: {
            id: params
        }
    })
}

/** 获取裁剪图，用于封面 */
export const getCutImgByCoverPic = async (params) => {
    return instance({
        url: '/dq_admin/duibanSampleData/dealImageResult',
        method: 'GET',
        params: {
            id: params
        }
    })
}

/** 确定使用封面图 */
export const postSetCoverPic = async (params) => {
    return instance({
        url: '/dq_admin/duibanSampleData/aquireCutImage',
        method: 'post',
        data: params
    })
}

/** 修改版别描述数据 */
export const postChangeVersionDescOrMark = async (params) => {
    return instance({
        url: '/dq_admin/kinditemversion/singleEditVersion',
        method: 'post',
        data: params
    })
}

/** 获取查询数据 */
export const getSampleStoreByIdApi = async (params) => {
    return instance({
        url: '/dq_admin/duibanSampleData/list',
        method: 'get',
        params: params
    })
}

/** 获取版别数据 */
export const getVersionListApi = async (params) => {
    return instance({
        url: '/dq_admin/kinditemversion/list2',
        method: 'GET',
        params: {
            pageNum: 1,
            versionName: params
        }
    })
}