<template>
  <!-- 推送样本库 -->
  <a-modal
      v-model="showModal"
      :width='1000'
      :title="titleText"
      ok-text="确认"
      cancel-text="取消"
      @cancel="cancelPushModal"
      @ok="confirmPushModal"
  >
    <div class="push-modal">
      <a-form-model
          ref="ruleForm"
          :model="curItem"
          v-bind="layout"
      >
        <!--    版别信息    -->
        <a-form-model-item label="版别信息：">
          <span class="color-red">*</span>
          <a-select
              :dropdownMatchSelectWidth="false"
              :filter-option="untils.filterOption"
              :dropdownMenuStyle="{'max-height': '350px'}"
              allowClear
              showSearch
              @search='searchData'
              class="ml-10 w-150"
              v-model="curItem.coinId"
              placeholder="请选择大币种"
              @change="handleChangeCoin($event)"
          >
            <a-select-option
                v-for="items of bigCoinList"
                :key="items.id"
                :value="items.id"
            >{{ items.coinKindName }}</a-select-option>
          </a-select>
          <!-- 选择小币种 -->
          <a-select
              :dropdownMatchSelectWidth="false"
              @change="coinItemChange"
              :filter-option="untils.filterOption"
              allowClear
              showSearch
              class="ml-10 w-150"
              v-model="curItem.coinItemId"
              placeholder="请选择小币种"
          >
            <a-select-option
                v-for="item of smallCoinList"
                :key="item.coinKindItemId"
                :value="item.coinKindItemId"
            >{{ item.coinKindItemName }}</a-select-option>
          </a-select>
          <!-- 选择版别 -->
          <a-select
              :dropdownMatchSelectWidth="false"
              allowClear
              showSearch
              :filter-option="untils.filterOption"
              class="ml-10 w-300"
              v-model="curItem.coinVersionId"
              @change="handleChangeVersion"
              :dropdownMenuStyle="{'max-height': '500px'}"
              placeholder="请选择版别"
          >
            <a-select-option
                v-for="item of versionList"
                :key="item.coinVersionId"
                :value="item.coinVersionId"
            >{{ `【${item.level ?  item.level : "无"}】 ${item.coinVersionName}` }}</a-select-option>
          </a-select>
        </a-form-model-item>
<!--    选择性质    -->
        <a-form-item label="选择性质">
          <span class="color-red">*</span>
          <div class="nature-text-list" >
            <div
                v-for="tag in natureTypeMapText"
                :key="tag.value"
                :class="tag.value === curItem.natureType ? 'active-tag' : ''"
                class="mr-10 mb-10 cur-pot font-size-32 p-10 nature-text-tag"
                @click="handleSelectedTag(tag)"
            >{{ tag.name }}</div>
          </div>
        </a-form-item>
        <!--    图片标题    -->
        <a-form-item label="图片标题：">
          <span class="color-red">*</span>
          <a-input
              allowClear
              v-model="curItem.title"
              class="ml-10 w-600"
              placeholder='图片标题'
          ></a-input>
        </a-form-item>
        <!--    评级信息    -->
        <a-form-item label="评级信息">
          <span class="color-red">*</span>
          <a-select
              allowClear
              showSearch
              class="w-150 ml-10"
              v-model="curItem.ratingCompanyType"
              placeholder="评级公司"
              @change="handleChangeSelectCompany"
          >
            <a-select-option
                v-for="item of ratingCompanyTypeList"
                :key="item.value"
                :value="item.value"
            >{{ item.name }}</a-select-option>
          </a-select>
          <a-input
              v-if="curItem.ratingCompanyType !== 0"
              allowClear
              v-model="curItem.ratingCompanyCode"
              class="w-250 ml-20"
              placeholder='评级编号'
          ></a-input>
        </a-form-item>
        <!--    钱币信息   -->
        <a-form-item label="钱币数据：">
          <div class="flex-start data-input">
            <a-input
                allowClear
                v-model="curItem.weightSizeInfo.weight"
                class="w-200 ml-10"
                placeholder='质量'
            ></a-input>
            <span class="ml-5">g</span>
            <a-input
                allowClear
                v-model="curItem.weightSizeInfo.size"
                class="w-200 ml-20"
                placeholder='尺寸'
            ></a-input>
            <span class="ml-5">mm</span>
          </div>
        </a-form-item>
        <!--    交易信息   -->
        <a-form-item label="成交信息">
          <a-date-picker
              class="ml-10"
              v-model="curItem.goodPriceInfo.tradeTime"
              placeholder="成交时间选择"
              @change="handleSelectedPicker"
          />
          <a-select
              allowClear
              showSearch
              class="w-150 ml-20"
              v-model="curItem.goodPriceInfo.tradePlatformType"
              placeholder="交易平台"
              @change-="handleChangeSelectTradePlatform"
          >
            <a-select-option
                v-for="item of tradingPlatformTypeList"
                :key="item.value"
                :value="item.value"
            >{{ item.name }}</a-select-option>
          </a-select>
          <a-input
              allowClear
              v-model="curItem.goodPriceInfo.tradePrice"
              class="w-150 ml-20"
              placeholder='交易金额'
          ></a-input>
          <span class="ml-5">元</span>
        </a-form-item>
        <!--    上传图片    -->
        <a-form-model-item label="上传图片：">
          <span class="color-red">*</span>
          <div class="flex-start ml-10">
            <div>
              <a-upload
                  :key="imgKey"
                  :action="ip + '/file/upload'"
                  list-type="picture-card"
                  :file-list="coverFrontList"
                  @preview="handlePreview"
                  @change="handleChangeCoverFrontList"
              >
                <div v-if="coverFrontList.length < 1">
                  <a-icon type="plus" />
                  <div class="ant-upload-text">上传正面图</div>
                </div>
              </a-upload>
            </div>
            <div class="ml-10">
              <a-upload
                  :key="imgKey"
                  :action="ip + '/file/upload'"
                  list-type="picture-card"
                  :file-list="coverBackList"
                  @preview="handlePreview"
                  @change="handleChangeCoverBackList"
              >
                <div v-if="coverBackList.length < 1">
                  <a-icon type="plus" />
                  <div class="ant-upload-text">上传反面图</div>
                </div>
              </a-upload>
            </div>
          </div>
        </a-form-model-item>
        <!--    图片说明    -->
        <a-form-model-item label="图片说明：">
          <a-input
              class="ml-10"
              v-model="curItem.descRemark"
              type="textarea"
              :rows="3"
              placeholder='描述说明'
          ></a-input>
        </a-form-model-item>
      </a-form-model>
    </div>
  </a-modal>
</template>

<script>
import untils from "@/untils";
import {mapState} from "vuex"
import {
  RATING_COMPANY_TYPE_LIST,
  TRADING_PLATFORM_TYPE_LIST
} from "@/views/cmsPage/coinBookManage/_data"
import { getBookImgType } from "@/axios/apis"

export default {
  data() {
    return {
      untils,
      titleText: '推送数据',
      showModal: false,
      curItem: {
        // 大币种id
        coinId: undefined,
        // 小币种id
        coinItemId: undefined,
        // 版别id
        coinItemVersionId: undefined,
        // 类型
        natureType: undefined,
        // 性质正面图
        frontImage: undefined,
        // 性质反面图
        backImage: undefined,
        // 描述
        descRemark: undefined,
        // 评级编号
        ratingCompanyCode: undefined,
        // 评级公司
        ratingCompanyType: undefined,
        // 泉谱名称
        quanpuBook: undefined,
        // 泉谱编号
        quanpuBookSerial: undefined,
        // 版权
        copyright: undefined,
        // 交易信息
        goodPriceInfo: {
          tradeTime: undefined,
          tradePrice: undefined,
          tradePlatformType: undefined,
          tradePlatformName: undefined
        },
        // 钱币信息
        weightSizeInfo: {
          weight: undefined,
          size: undefined
        },
        title: undefined,
      },
      natureTypeMapText: [],
      tradingPlatformTypeList: TRADING_PLATFORM_TYPE_LIST,
      ratingCompanyTypeList: RATING_COMPANY_TYPE_LIST,
      imgKey: Math.random() * 10 + '_smm',
      coverFrontList: [],
      coverBackList: [],
      bigCoinList: [],
      smallCoinList: [],
      versionList: [],
      natureId: undefined,
      layout: {
        //标签占5份  共24份
        labelCol: { span: 2 },
        wrapperCol: { span: 22 },
      },
    };
  },
  computed: {
    ...mapState(['ip'])
  },
  methods: {
    /** 获取图片性质 */
    async getImgTypeList() {
      const res = await getBookImgType();
      this.$loading.hide()
      if (res.status !== "200") return;
      const tempList = res.data.records.map(el => {
        return {value: el.id, name: el.natureName}
      })
      this.natureTypeMapText = tempList.filter(el => el.value !== 6);
    },
    /** 选择拍品 */
    handleSelectedTag(data) {
      this.curItem.natureType = data.value
      this.curItem.title = data.name
    },
    /** 选择时间 */
    handleSelectedPicker(date, dateString) {
      this.curItem.goodPriceInfo.tradeTime= dateString
    },
    /** 显示 */
    async show(data) {
      this.$loading.show()
      await this.getImgTypeList();
      await this.setDefault(data)
      this.$loading.hide()
      this.showModal = true;
    },
    /** 设置默认值 */
    async setDefault(data) {
      this.curItem = Object.assign(this.curItem, data)
      if (data.goodPriceInfo) {
        this.curItem.goodPriceInfo.tradePlatformType = Number(data.goodPriceInfo.tradePlatformType)
      } else {
        this.curItem.goodPriceInfo = {
          tradeTime: undefined,
          tradePrice: undefined,
          tradePlatformType: undefined,
          tradePlatformName: undefined,
        }
      }
      if (!data.weightSizeInfo) {
        this.curItem.weightSizeInfo = {
          weight: undefined,
          size: undefined
        }
      }
      const res = await this.axios("/dq_admin/kind/list", {
        params: { coinName: this.curItem.coinName },
      });
      if (res.status !== "200") return;
      const { records } = res.data;
      this.bigCoinList = records
      await this.handleChangeCoin(this.curItem.coinId)
      this.$set(this.curItem, "coinItemId", data.coinItemId);
      if (this.curItem.frontImage) {
        this.coverFrontList = [{
          uid: Math.random() * 10 + '_smm',
          name: "image",
          status: "done",
          url: this.curItem.frontImage,
        }]
      }
      if (this.curItem.backImage) {
        this.coverBackList = [{
          uid: Math.random() * 10 + '_smm',
          name: "image",
          status: "done",
          url: this.curItem.backImage,
        }]
      }
      this.$set(this.curItem, "coinVersionId", data.coinItemVersionId);
    },
    /** 关闭弹窗 */
    cancelPushModal() {
      this.curItem = {
        // id
        id: undefined,
        // 大币种id
        coinId: undefined,
        // 小币种id
        coinItemId: undefined,
        // 版别id
        coinItemVersionId: undefined,
        // 类型
        natureType: undefined,
        // 性质正面图
        frontImage: undefined,
        // 性质反面图
        backImage: undefined,
        // 描述
        descRemark: undefined,
        // 评级编号
        ratingCompanyCode: undefined,
        // 评级公司
        ratingCompanyType: undefined,
        // 泉谱名称
        quanpuBook: undefined,
        // 泉谱编号
        quanpuBookSerial: undefined,
        // 版权
        copyright: undefined,
        // 交易信息
        goodPriceInfo: {
          tradeTime: undefined,
          tradePrice: undefined,
          tradePlatformType: undefined,
          tradePlatformName: undefined
        },
        // 钱币信息
        weightSizeInfo: {
          weight: undefined,
          size: undefined
        },
        title: undefined,
      }
      this.coverBackList = []
      this.coverFrontList = []
      this.showModal = false
    },
    /** 确定添加，或编辑 */
    async confirmPushModal() {
      if (!this.curItem.coinId) return this.$message.warning('请选择大币种')
      if (!this.curItem.coinItemId) return this.$message.warning('请选择小币种')
      if (!this.curItem.coinVersionId) return this.$message.warning('请选择版别')
      if (this.curItem.coinVersionId) {
        this.curItem.coinItemVersionId = this.curItem.coinVersionId
      }
      if (!this.curItem.coinItemVersionId) return this.$message.warning('请选择版别')
      if (this.coverFrontList.length <= 0) return this.$message.warning('请上传正面图')
      if (this.coverBackList.length <= 0) return this.$message.warning('请上传反面图')
      if (!this.curItem.natureType) return this.$message.warn('请选择图片性质')

      if (this.curItem.ratingCompanyType === undefined || this.curItem.ratingCompanyType == null ) return this.$message.warn('请选择评级公司')
      if (this.curItem.ratingCompanyType !== 0 && !this.curItem.ratingCompanyCode) return this.$message.warn('评级编号不能为空')

      this.curItem.frontImage = this.coverFrontList.map(row => row.url)[0]
      this.curItem.backImage = this.coverBackList.map(row => row.url)[0]
      const res = await this.axios.post('/dq_admin/coinVersionNature/addOrUpdate', this.curItem)
      if (res.status !== '200') return
      this.$message.info('推送成功')
      this.$emit('success')
      this.cancelPushModal()
    },
    /** 获取大币种列表 */
    searchData(inner){
      if (!inner) return
      this.axios('/dq_admin/kind/list',{params:{
          coinName: inner
        }}).then(res=>{
        if(res.status === '200'){
          const {records} = res.data
          this.bigCoinList = records
        }
      })
    },
    /** 选择大币种 */
    handleChangeCoin(e) {
      this.bigCoinText = this.bigCoinList.find(el => el.id === e).coinKindName
      delete this.curItem.coinItemId;
      delete this.curItem.coinItemVersionId
      if (!e) {
        this.bigCoinList = []
        this.smallCoinList = [];
        this.versionList = [];
        return;
      }
      this.getSmallCoinList();
    },
    /** 查询小币种 */
    async getSmallCoinList() {
      const res = await this.axios("/dq_admin/duibanConfirm/listCoinItems", {
        params: {
          coinId: this.curItem.coinId,
        },
      });
      if (res.status !== '200') return;
      this.smallCoinList = res.data;
      if (this.curItem.coinItemId) {
        await this.getVersionType()
      }
    },
    /** 选择小币种 */
    coinItemChange(val) {
      delete this.curItem.coinItemVersionId;
      delete this.curItem.coinVersionId
      if (!val) {
        this.versionList = [];
        return;
      }
      this.getVersionType();
    },
    /** 获取版别 */
    async getVersionType() {
      const res = await this.axios("/dq_admin/duibanConfirm/listCoinVersions", {
        params: {
          coinId: this.curItem.coinId,
          coinItemId: this.curItem.coinItemId,
        },
      });
      if (res.status != 200) return;
      this.versionList = res.data;
    },
    /** 改变辨别 */
    async handleChangeVersion(e) {
      this.curItem.coinItemVersionId = e
    },
    /** 显示大图 */
    handlePreview(file) {
      this.$previewImg({
        list: [{img_url: `${file.url}`}],
        baseImgField: "img_url",
        showMute: false,
      });
    },
    /** 上传图片-正面 */
    handleChangeCoverFrontList({ fileList }) {
      fileList.map((row) => {
        try {
          if (row.response.data) {
            row.url = row.response.data;
          }
        } catch (e) {console.log('')}
      });
      this.coverFrontList = fileList;
    },
    /** 上传图片-背面 */
    handleChangeCoverBackList({ fileList }) {
      fileList.map((row) => {
        try {
          if (row.response.data) {
            row.url = row.response.data;
          }
        } catch (e) {console.log('')}
      });
      this.coverBackList = fileList;
    },
    /** 修改评级公司 */
    handleChangeSelectCompany() {
      if (this.curItem.ratingCompanyType === 0) {
        this.curItem.ratingCompanyCode = undefined
      }
    },
    /** 切换选择评级 */
    handleChangeSelectTradePlatform(e) {
      this.curItem.goodPriceInfo.tradePlatformName = this.tradingPlatformTypeList.find(el => el.value === e).name
    },
    /** 改变选择图片性质 */
    handleChangeSelectNatureType(e) {
      this.curItem.title = this.natureTypeMapText.find(el => el.value === e).name
    }
  },
};
</script>

<style lang="scss" scoped>
.flex-start {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.ml-10 {
  margin-left: 10px;
}
.data-input {
  span {
    font-size: 15px;
    font-weight: bold;
  }
}
.nature-text-list {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
}
.nature-text-tag {
  padding: 10px 20px;
  height: 40px;
  border-radius: 10px;
  border: 1px solid gray;
  background-color: gray;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
}
.active-tag {
  border: 1px solid #2d8cf0;
  background-color: green;
}
</style>