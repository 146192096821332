export const sourceList = [
  { name: "标注确认", value: 1 },
  { name: "手动上传", value: 2 },
  { name: "GB/BC推送", value: 3 },
  { name: "QH推送", value: 4 },
  { name: "对版记录推送", value: 5 },
  { name: "批量回答推送", value: 8 },
]

export const sourceValue = (value)=>{
  const result = sourceList.find(el=>{
    return value == el.value
  })
  return result.name
}

export const isDiKuTypeList = [
  { name: '不在底库', value: 0 },
  { name: '底库存在', value: 1 },
  { name: '全部', value: 2 },
]
export const waitStatusList = [
  { name: '已处理', value: 0 },
  { name: '困难', value: 1 },
]

export const isDuiBanList = [
  { name: '支持对版', value: 1 },
]