<template>
  <a-drawer
    title="操作日志"
    placement="right"
    :width="'60vw'"
    :closable="true"
    :visible="visible"
    @close="onClose"
  >
    <div style="height: 80vh; overflow-y: scroll">
      <div class="notes">
        <a-input v-model="notes" placeholder="追加备注"></a-input>
        <a-button type="primary" @click="handleAppendNotes">追加备注</a-button>
      </div>
      <a-table
        @change="changePage"
        :pagination="pagination"
        :loading="loading"
        :columns="columns"
        :data-source="list"
        :rowKey="(record, index) => index"
      >
      </a-table>
    </div>
  </a-drawer>
</template>

<script>
export default {
  data() {
    return {
      notes: undefined,
      visible: false,
      list: [],
      loading: false,
      params: {
        pageNum: 1,
        pageSize: 20,
        libraryType: 0,
        libraryId: ""
      },
      pagination: {
        total: 0,
        current: 1,
        pageSize: 20,
        showTotal: (total) => `共${total}条`,
      },
      columns: [
        {
          align: "center",
          title: "操作时间",
          dataIndex: "createTime",
          width: 120,
        },
        {
          align: "center",
          title: "操作用户",
          dataIndex: "adminName",
          width: 120,
        },
       {
          align: "center",
          title: "操作日志",
          dataIndex: "actionContent",
        },
        
      ],
    };
  },
  methods: {
    /** 追加备注 */
    async handleAppendNotes() {
      if (!this.notes) return this.$message.warning('备注不能为空')
      const res = await this.axios('/dq_admin/duibanConfirmDataLog/addConfirmDataLog', {
        params: {
          libraryId: this.params.libraryId,
          libraryType: 0,
          actionContent: this.notes
        }
      })
      if (res.status == '200') {
        this.notes = undefined
        this.$message.success(res.message)
        await this.getList()
      }
    },
    show(data){
      this.notes = undefined
      this.$set(this.params, "libraryId", data.id)
      this.visible = true
      this.getList()

    },
    async getList() {
      this.loading = true;
      const res = await this.axios(
        "/dq_admin/duibanConfirmDataLog/getConfirmDataLog",
        {
          params: this.params,
        }
      );
      this.loading = false;
      if (res.status != 200) return (this.list = []);
      this.list = res.data.records;
      this.pagination.total = res.data.total;
    },
    // 切换分页
    changePage(page) {
      const { current, pageSize } = page;
      this.pagination.current = current;
      this.params.pageNum = current;
      this.params.pageSize = pageSize;
      this.getList();
    },
    onClose() {
      this.visible = false;
      setTimeout(() => {
        this.$emit("close");
      }, 300);
    },
  },
};
</script>

<style lang="scss" scoped>
.notes {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 10px;
  input {
    width: 300px;
    margin-right: 20px;
  }
}
::v-deep {
  .ant-table-thead > tr > th,
  .ant-table-tbody > tr > td {
    height: 60px !important;
  }
}
</style>