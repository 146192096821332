import instance from "@/axios"

/** 搜索朝代---根据文本搜索 */
export const getDynastyListByName = async (str) => {
    return instance({
        url: '/dq_admin/dynasty/searchDynasty',
        method: 'GET',
        params: { dynastyName: str },
    })
}
/** 根据朝代ID搜索大币种 */
export const getBigCoinListByDynastyId = async (id) => {
    return instance({
        url: '/dq_admin/kind/listCoinListByDynastyId',
        method: 'GET',
        params: { dynastyId: id },
    })
}

/** 搜索大币种---根据文本搜索 /dq_admin/kind/list*/
export const getBigCoinList = async (str) => {
    return instance({
        url: '/dq_admin/kind/list',
        method: 'GET',
        params: { coinName: str },
    })
}

/** 搜索小币种  /dq_admin/kind/getItemAndVersionNew 参数：coinSid: 大币种对应的sid
 * @params {String} coinSid - 大币种sid
 * */
export const getSmallCoinList = async (coinSid) => {
    return instance({
        url: '/dq_admin/kind/getItemAndVersionNew',
        method: 'GET',
        params: { sid: coinSid },
    })
}

/** 获取版别 /dq_admin/duibanConfirm/listCoinVersions
 * @params {Number} coinId - 大币种ID
 * @params {Number} coinItemId - 小币种ID
 * */
export const getCoinVersionList = async (coinId, coinItemId) => {
    return instance({
        url: '/dq_admin/duibanConfirm/listCoinVersions',
        method: 'GET',
        params: {coinId, coinItemId},
    })
}
export const getCoinVersionListByPriceQuery = async (coinId, coinItemId) => {
    return instance({
        url: '/dq_admin/coinSilverVersionGoods/countPriceVersionsSize',
        method: 'GET',
        params: {coinId, coinItemId},
    })
}

/** 获取图片性质 */
export const getBookImgType = async () => {
    return instance({
        url: '/dq_admin/tukuNatureType/list',
        method: 'GET',
        params: {
            pageNum: 1,
            pageSize: 100,
            id: undefined,
            natureName: undefined
        }
    })
}

/** 添加活谱图片列表 */
export const addBookImgTypeList = async (params) => {
    return instance({
        url: '/dq_admin/tukuNatureType/addOrUpdate',
        method: 'POST',
        data: params
    })
}

/** 抠图测试 */
export const getImgCut = async (imgUrl, version) => {
    return instance({
        url: '/dq_admin/coinVersionNature/cutoutPointsImage',
        method: 'GET',
        params: {
            imageUrl: imgUrl,
            modelType: version
        }
    })
}

/** 用户检索---支持userID，userName */
export const getUserInfoByUserIdOrUserNameApi = async (searchName) => {
    return instance({
        url: '/dq_admin/user/getUserList',
        method: 'GET',
        params: {
            pageNum: 1,
            pageSize: 10,
            searchName: searchName,
        }
    })
}
