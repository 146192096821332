var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    {
      attrs: { width: 800, title: "原图", footer: null },
      on: { cancel: _vm.close },
      model: {
        value: _vm.showModel,
        callback: function($$v) {
          _vm.showModel = $$v
        },
        expression: "showModel"
      }
    },
    [
      _c("div", { staticClass: "content" }, [
        _c("div", { staticClass: "img" }, [
          _c("img", { attrs: { src: _vm.imgInfo.front, alt: "" } })
        ]),
        _c("div", { staticClass: "img" }, [
          _c("img", { attrs: { src: _vm.imgInfo.back, alt: "" } })
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }