<template>
  <div>
    <a-modal
        v-model="showModal"
        :keyboard="false"
        :bodyStyle='{
        padding: "10px",
      }'
        :width='1400'
        @cancel='cancel'
    >
      <div class="content">
        <div class="con-body">
          <div class="c-right">
            <div
                class="image-view"
                v-for="(item, index) in showImages"
                :class="{
                bg: item.type == 1
              }"
                :key="index"
            >
              <div class="c-info">
                <img :src="versionImage" @click="previewImg" />
              </div>
              <div
                  class="image-item"
                  v-for="(item2, index2) in item.list"
                  :key="index2"
              >
                <div class="image-box">
                  <img
                      class="image"
                      :src="item2.url"
                  />
                  <div class="image-box-fixed2">
                    <div>{{item2.ranking}}</div>
                  </div>
                  <div class="image-box-fixed">
                    <div>{{item2.id}}</div>
                    <div>{{item2.distance}}</div>
                  </div>
                </div>

                <div class="item-info">
                  <div>{{item2.versionName}}</div>
                  <div v-if="pageType != 1">{{item2.tagNames | tagNameFilter}}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <template #footer>
        <div class="footer">
          <div>
            <a-radio-group
                size="small"
                v-model="imageType"
                :default-value="0"
                button-style="solid"
            >
              <a-radio-button :value="0">
                正面图
              </a-radio-button>
              <a-radio-button :value="1">
                背面图
              </a-radio-button>
            </a-radio-group>
          </div>
          <div class="footer-btn">
            <a-button @click="cancel()">关闭</a-button>
          </div>
        </div>
      </template>
    </a-modal>
  </div>

</template>

<script>
export default {
  data() {
    return {
      showModal: false,
      imageType: 0,
      versionId: 0,
      pageType: 0,
      originalInfo: {},
      recordList: []
    };
  },
  filters: {
    tagNameFilter(val) {
      return val.replaceAll(",", " | ");
    },
  },
  computed: {
    versionImage() {
      const { versionFrontImage, versionBackImage } = this.originalInfo;
      return this.imageType == 0 ? versionFrontImage : versionBackImage;
    },
    showImages() {
      return this.recordList.map((el) => {
        const list = el.list.map((el2) => {
          return {
            ...el2,
            url:
                this.imageType == 0
                    ? el2.versionFrontImage
                    : el2.versionBackImage,
          };
        });
        return {
          ...el,
          list,
        };
      });
    },
  },
  methods: {
    show(id) {
      this.imageType = 0;
      this.versionId = id
      this.getInfo();
    },
    // 预览图片
    previewImg(){
      const list = [{
        img_url: this.versionImage
      }];
      this.$previewImg({
        list: list,
        baseImgField: "img_url",
        showMute: false,
      });
    },
    // 默认值
    async getInfo() {
      this.$loading.show();
      const res = await this.axios("/dq_admin/duibanSampleData/sampleDuiban", {
        params: {
          recordId: this.versionId
        },
      });
      this.$loading.hide();
      if (res.status != 200) return;
      const {originalInfo, recordList} = res.data
      this.originalInfo = JSON.parse(JSON.stringify(originalInfo));
      this.recordList = JSON.parse(JSON.stringify(recordList));
      this.showModal = true;
    },
    // 关闭
    cancel() {
      this.originalInfo = {}
      this.recordList = []
      this.imageType = 0
      this.showModal = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.btn-box-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.footer-btn {
  margin-right: 20px;
  button {
    width: 240px;
  }
}
.body-top {
  display: flex;
  justify-items: flex-start;
  align-items: center;

}
.go-to {
  color: #1890ff !important;
  cursor: pointer !important;
  text-decoration: underline;
}
.refactor-version {
  margin-left: 20px;
}
.con-body {
  max-height: 500px;
  overflow-y: scroll;
  display: flex;
  padding-top: 10px;
}
.c-info {
  margin-right: 10px;
  margin-bottom: 10px;
  height: 100%;
  img {
    width: 200px;
    height: 200px;
  }
}
.image-view {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 10px;
  padding-bottom: 10px;
  &.bg {
    background: #e6f7ff;
  }
  .image-item {
    width: 200px;
    height: 200px;
    margin-right: 10px;
  }
  .image-box {
    width: 200px;
    height: 200px;
    text-align: center;
    position: relative;
    .image {
      height: 200px;
      width: 200px;
    }
    .image-box-fixed {
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      display: flex;
      justify-content: space-between;
      background: rgba(0, 0, 0, 0.3);
      color: #fff;
      font-weight: bold;
      padding: 0 6px;
    }
    .image-box-fixed2 {
      position: absolute;
      right: 0;
      top: 0;
      width: 10%;
      display: flex;
      justify-content: space-between;
      background: rgba(0, 0, 0, 0.3);
      color: rgb(234, 27, 27);
      font-weight: bold;
      padding: 0 6px;
    }
  }
  .item-info {
    margin-bottom: 5px;
    text-align: center;
    & > div {
      margin-top: 2px;
    }
  }
}
.item-btn {
  display: flex;
  align-items: center;
}
.footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

::v-deep .ant-modal {
  top: 50%;
  transform: translateY(-50%);
}
::v-deep .ant-modal-footer {
  padding: 10px 7px;
}
</style>